import { createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9bd406a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-container w_1200",
  "element-loading-text": "加载中..."
}

import MyTitle from '../components/MyTitle.vue'
import CompanyBlock from './companyInfoBlock.vue'
import OpenRegionSelect from '../../components/OpenRegionSelect.vue'
import {$apiUnionInfo,$apiOpenAddress } from '@/api/modules/deal.js'
import { $apiStoreList } from '@/api/modules/store';
import { reactive } from 'vue'
import {  A11y,Autoplay,EffectFade,Navigation, Pagination, Scrollbar, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";

export default {
  setup(__props) {

let modules = [ A11y,Autoplay,EffectFade,Navigation, Pagination, Scrollbar]

const info = reactive({
  unionList:[],
  unionInfoLoading:true,
  regionList:[],
  regionId:0,
})
const region_id = JSON.parse(localStorage.getItem('regionInfo'))?.two_id
// 联盟商家信息
const getUnionInfo = async()=>{
  info.unionInfoLoading = true
  let {data} = await $apiUnionInfo({regionId:info.regionId})
  if(data){
    data = data.slice(0,20)
    data.map((item)=>{
      item.gallery = item.gallery.slice(0,3)
      const lth = item.gallery.length
      item.style = {width:lth === 1 ?'100%':(lth === 2?'calc(50% - 4px)' : 'calc(33% - 2px)')}
    })
  }
  info.unionList = data
  info.unionInfoLoading = false
}
getUnionInfo()
// 更新联盟商家信息
const getUnionList = async()=>{
  const {data,status} = await $apiStoreList({region_id:info.regionId})
  if(status){
    localStorage.setItem('unionList',JSON.stringify(data))
  }
}
getUnionList()

// 获取地区
const getRegionId = (id)=>{
  info.regionId = id
  getUnionInfo()
}

return (_ctx, _cache) => {
  const _component_el_empty = _resolveComponent("el-empty")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyTitle, {
      title: "品牌认证 • 联盟商家",
      moreLink: "/deal/union"
    }, {
      right_content: _withCtx(() => [
        _createVNode(OpenRegionSelect, { onGetRegionId: getRegionId })
      ]),
      _: 1
    }),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (!_unref(info).unionInfoLoading&&_unref(info).unionList.length)
        ? (_openBlock(), _createBlock(_unref(Swiper), {
            key: 0,
            modules: _unref(modules),
            "space-between": 10,
            slidesPerView: 3.2,
            navigation: "",
            watchSlidesVisibility: "",
            loop: _unref(info).unionList?.length>3,
            autoplay: {delay: 2500,disableOnInteraction: false,pauseOnMouseEnter:true},
            lazy: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(info).unionList, (item) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(CompanyBlock, { info: item }, null, 8, ["info"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules", "slidesPerView", "loop"]))
        : _createCommentVNode("", true),
      (!_unref(info).unionInfoLoading&&!_unref(info).unionList.length)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            "image-size": 150,
            description: "该站点暂无联盟商家信息，入驻请联系400-699-0208！"
          }))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _unref(info).unionInfoLoading]
    ])
  ], 64))
}
}

}